html,
body {
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
}

canvas {
  cursor: grab;
  outline: none;
}
